<template>
  <div class="d-flex flex-wrap">
    <v-card
      outlined
      class="polar"
    >
      <div
        v-if="!publisher"
        style="height:262px"
      >
        <v-sheet
          color="black"
          width="320"
          height="240"
        >
          {{ $t('salle_p.pas_de_video') }}
        </v-sheet>
      </div>
      <div
        v-if="publisher"
        id="session"
        style="height:262px"
      >
        <div
          id="main-video"
          width="320px"
        >
          <user-video :stream-manager="publisher" />
        </div>
      </div>

      <!-- VIDEO -->
      <v-btn
        :class="partageCamera ? 'success mx-1' : 'grey mx-1'"
        icon
        @click="toggleCamera()"
      >
        <v-icon>mdi-video-outline</v-icon>
      </v-btn>

      <!-- AUDIO -->
      <v-btn
        :class="partageMicro ? 'success mx-1' : 'grey mx-1'"
        icon
        @click="toggleMicro()"
      >
        <v-icon>mdi-microphone</v-icon>
      </v-btn>

      <!-- PARTAGE ÉCRAN -->
      <v-btn
        :class="partageÉcran ? 'success mx-1' : 'grey mx-1'"
        icon
        @click="toggleEcran()"
      >
        <v-icon>mdi-monitor-screenshot</v-icon>
      </v-btn>
    </v-card>

    <!-- <div
      v-for="(sub, index) in subscribers"
      :key="index"
    >
      <v-card
        outlined
        class="polar"
      >
        <user-video
          class="cadreVideo"
          :stream-manager="sub"
          @click.native="updateMainVideoStreamManager(sub)"
        />
      </v-card>
    </div> -->
    <div ref="containerVideo" />
    <v-btn
      v-if="muted"
      dark
      small
      class="green white--text"
      @click="unmute"
    >
      <v-icon>
        mdi-volume-high
      </v-icon>
      <span class="pl-3">
        {{ $t('general.activer_le_son') }}
      </span>
    </v-btn>
  </div>
</template>
<style scoped>
.polar{
  padding:9px;
  width:340px;
  height: 332px;
  margin: 8px;
}
.cadreVideo{
  border:2px solid black;
  width:324px; height:244px
}
</style>
<script>
  import UserVideo from './UserVideo'
  import { OpenVidu } from 'openvidu-browser'
  import restApiService from '@/services/restApiService.js'
  import Vue from 'vue'

  var debug = require('debug')('orka:OvSalon')

  export default {
    components: {
      UserVideo,
    },
    props: {
      nomSalon: String,
    },
    data: () => ({
      OV: undefined,
      session: undefined,
      token: undefined,
      publisher: undefined,
      subscribers: [],
      width: 320,
      height: 240,
      partageCamera: false,
      partageMicro: false,
      partageÉcran: false,
      videoSource: undefined,
      videoElements: {},
      muted: false,
    }),

    computed: {
      publishAudio () {
        return this.partageMicro
      },
      publishVideo () {
        return this.partageCamera || this.partageÉcran
      },
      etaitPartageEcran () {
        return this.videoSource === 'screen'
      },
      etaitPartageCamera () {
        return typeof this.videoSource === 'undefined'
      },
    },
    watch: {
      nomSalon: {
        handler (newValue) {
          if (newValue) {
            debug('OvExposant -> setted salon = ' + this.nomSalon)
            this.initOpenVidu()
          }
        },
      },
    },
    created () {
      debug('OvExposant -> created salon = ' + this.nomSalon)
    },

    beforeDestroy () {
      this.leaveSession()
    },

    methods: {
      initOpenVidu () {
        debug('initOpenVidu')
        // Initialiation de openVidu
        const options = {
          loglevel: 2, // https://github.com/OpenVidu/openvidu/issues/243
        }
        this.OV = new OpenVidu(options)

        this.session = this.OV.initSession()
        debug('nox:', this.session)
        this.session.on('streamCreated', ({ stream }) => {
          debug('OvKiosque->streamCreated')
          const subscriber = this.session.subscribe(stream, this.$refs.containerVideo)
          this.subscribers.push(subscriber)

          subscriber.on('videoElementCreated', event => {
            const vidEl = event.element
            this.videoElements[vidEl.id] = vidEl
            vidEl.autoplay = true
            vidEl.playsinline = true
            vidEl.style = 'width:320px;'
            setTimeout(() => {
              vidEl.play().then(() => {
                console.error('Can play media unmuted')
              }).catch(() => {
                console.error('Can\'t play media unmuted')
                this.$emit('playblocked', true)
                vidEl.muted = true
                this.muted = true
                vidEl.play().then(() => console.error('Can play if muted')).catch((err) => console.error('Still fails even muted', err))
              })
            }, 100)
          })

          subscriber.on('videoElementDestroyed', event => {
            const vidEl = event.element
            Vue.delete(this.videoElements, vidEl.id)
            if (this.videoElements.length === 0) {
              this.muted = false
            }
          })
        })

        this.session.on('streamPlaying', ({ stream }) => {
          debug('OvKiosque->streamPlaying')
        })
        // On every Stream destroyed...
        this.session.on('streamDestroyed', ({ stream }) => {
          debug('OvKiosque->streamDestroyed')
          const index = this.subscribers.indexOf(stream.streamManager, 0)
          if (index >= 0) {
            this.subscribers.splice(index, 1)
          }
        })

        const context = `ORKA-Salon-${this.nomSalon}`
        debug('context: ' + context)

        restApiService.post('/api/openvidu/api-sessions/get-token',
                            {
                              sessionName: context,
                              context: context,
                            },
        )
          .then((response) => {
            this.token = response.data[0]
            debug('OvKiosque->token' + this.token)
            this.session.connect(this.token)
              .then(() => {
                debug('OvKiosque->connected')
              })
              .catch(error => {
                console.error('There was an error connecting to the session:', error, this.token)
              })
          },
          )
        window.addEventListener('beforeunload', this.leaveSession)
      },

      toggleCamera () {
        debug('OvKiosque->activerCaméra')

        if (this.partageCamera) {
          this.partageCamera = false
        } else {
          this.partageCamera = true

          if (this.partageÉcran) {
            this.partageÉcran = false
          }
        }

        if (this.publisher && !this.etaitPartageEcran) {
          this.publisher.publishVideo(this.publishVideo)

          if (!this.publishVideo && !this.publishAudio) {
            this.session.unpublish(this.publisher)
            this.publisher = null
          }
        } else {
          this.republish()
        }
      },

      unmute () {
        Object.keys(this.videoElements).forEach(key => {
          const el = this.videoElements[key]
          el.muted = false
        })
        this.muted = false
      },

      toggleMicro () {
        this.partageMicro = !this.partageMicro
        if (this.publisher) {
          this.publisher.publishAudio(this.publishAudio)

          if (!this.publishVideo && !this.publishAudio) {
            this.session.unpublish(this.publisher)
            this.publisher = null
          }
        } else {
          this.republish()
        }
      },

      toggleEcran (publish) {
        debug('OvKiosque->activerCaméra')

        if (this.partageÉcran) {
          this.partageÉcran = false
        } else {
          this.partageÉcran = true

          if (this.partageCamera) {
            this.partageCamera = false
          }
        }

        if (this.publisher && !this.etaitPartageCamera) {
          this.publisher.publishVideo(this.publishVideo)
          if (!this.publishVideo && !this.publishAudio) {
            this.session.unpublish(this.publisher)
            this.publisher = null
          }
        } else {
          this.republish()
        }
      },

      leaveSession () {
        debug('OvKiosque->leaveSession')
        // --- Leave the session by calling 'disconnect' method over the Session object ---
        if (this.session) this.session.disconnect()

        this.session = undefined
        this.publisher = undefined
        this.subscribers = []
        this.OV = undefined
        this.partageCamera = false
        this.partageMicro = false
        this.partageÉcran = false
        this.videoSource = undefined

        window.removeEventListener('beforeunload', this.leaveSession)
      },

      urlImageSansVideo () {
        return restApiService.mediaUrl(this.kiosque.mediaImage)
      },

      republish () {
        if (this.publisher) {
          this.session.unpublish(this.publisher)
          this.publisher = null
        }

        setTimeout(() => {
          debug(`Publish video ${this.publishVideo}, publish audio ${this.publishAudio}, video source: ${this.publisher ? this.publisher.videoSource : 'none'}`)

          if (this.partageCamera || this.partageMicro || this.partageÉcran) {
            let newVideoSource
            if (this.partageÉcran) {
              newVideoSource = 'screen'
            }

            this.videoSource = newVideoSource

            const publisher = this.OV.initPublisher(undefined, {
              audioSource: undefined, // The source of audio. If undefined default microphone
              videoSource: newVideoSource, // The source of video. If undefined default webcam
              publishAudio: this.publishAudio, // Whether you want to start publishing with your audio unmuted or not
              publishVideo: this.publishVideo, // Whether you want to start publishing with your video enabled or not
              resolution: `${this.width}x${this.height}`, // The resolution of your video
              frameRate: 30, // The frame rate of your video
              insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
              mirror: false, // Whether to mirror your local video or not
            }, (error) => { // Function to be executed when the method finishes
              if (error) {
                console.error('Error while initializing publisher: ', error)
              } else {
                debug('Publisher successfully initialized')
              }
            })

            this.publisher = publisher
            this.session.publish(this.publisher)
          } else {
            this.publisher = null
          }
        }, 200)
      },
    },
  }
</script>
