<template>
  <v-container fluid>
    <h1 class="font-weight-light mb-2 headline">
      {{ infoSalon.nom }}
    </h1>

    <!-- <v-card
      v-for="présence in infoSalon.listePrésences"
      :key="présence.usager.id"
      width="300px"
    >
      <v-card-title>{{ présence.usager.prenom }} {{ présence.usager.nom }}</v-card-title>
      <v-card-text>Punk</v-card-text>
    </v-card> -->
    <meeting-provider
      v-if="fournisseurRtc.valeur === 'videosdk' && usager"
      v-slot="{ meetingContext }"
      :meeting-id="$route.params.salon"
      :name="usager.prenom"
      class="my-4"
    >
      <salle-privee-meeting-container
        v-if="meetingContext.meetingJoined"
        :context="meetingContext"
      />
    </meeting-provider>

    <v-row>
      <v-col cols="6">
        <ov-salon
          v-if="fournisseurRtc.valeur === 'openvidu'"
          ref="ovSalon"
          :nom-salon="infoSalon.nom"
        />
      </v-col>
      <v-col cols="6">
        <chat
          :chaine="chaineChat"
          :user-info="$store.state.userInfo"
          :titre="$t('salle_p.clavardage')"
        />
        <v-btn
          class="primary"
          @click="onQuitterSallePrivée()"
        >
          {{ $t('salle_p.quitter_la_salle_privee') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import Salon from '@/services/salon'
  import OvSalon from '@/views/composantes/OvSalon'
  import Chat from '@/agora/Chat'
  import MeetingProvider from '../../videosdk/MeetingProvider'
  import SallePriveeMeetingContainer from '../../videosdk/SallePriveeMeetingContainer'
  import restApiService from '@/services/restApiService.js'
  import { mapGetters } from 'vuex'
  // var debug = require('debug')('orka:SallePri')

  export default {
    components: {
      OvSalon,
      Chat,
      MeetingProvider,
      SallePriveeMeetingContainer,
    },
    data: () => ({
      salon: null,
      usager: null,
      infoSalon: {},
      chaineChat: undefined,
    }),
    computed: {
      ...mapGetters(['fournisseurRtc']),
    },
    beforeRouteUpdate (to, from, next) {
      next()
      this.$refs.ovSalon?.leaveSession()
      this.maj()
    },
    mounted () {
      this.maj()
      this.getInfoUsager()
    },
    methods: {
      onQuitterSallePrivée () {
        this.salon.sortir()
        history.back()
      },
      maj () {
        const identifiant = this.$route.params.salon
        this.chaineChat = 'ORKA-Chat-' + identifiant
        this.salon = new Salon(identifiant)
        this.salon.getSalon().then(info => {
          this.infoSalon = info
        })
      },

      getInfoUsager() {
        restApiService
          // .get(`/api/usagers/${this.$store.state.userInfo.id}`)
          .get('/api/webUser/infoUsager')
          .then((result) => {
            this.usager = result.data.usager
          })
          .catch((erreur) => alert(erreur))
      },
    },
  }
</script>
