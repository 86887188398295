<template>
  <div class="salle-privee-meeting-container">
    <!-- {{ context.muted }} -->
    <v-row>
      <!-- {{ focusedParticipant ? focusedParticipant.id : 'NIL' }} ::
      {{ screenShareParticipant ? screenShareParticipant.id : 'NIL' }} -->
      <v-container>
        <v-row>
          <v-col
            ref="focusContainer"
            lg="10"
          >
            <div v-if="screenShareParticipant">
              <!-- SCREEN -->
              <participant-viewer
                v-for="participant in [screenShareParticipant]"
                :key="participant.id"
                :participant="participant"
                :screen-share="true"
              />
            </div>
            <div v-else-if="focusedParticipant">
              <!-- VIDEO -->
              <participant-viewer
                v-for="participant in [focusedParticipant]"
                :key="participant.id"
                :participant="participant"
              />
            </div>
          </v-col>

          <v-col
            v-if="!$vuetify.breakpoint.mobile"
            lg="2"
            class="hidden-md-and-down"
          >
            <v-row
              v-for="participant in sidebarParticipants"
              :key="participant.id"
              style="padding: 0; margin-bottom: 8px;"
            >
              <participant-viewer
                style="max-width: 100%"
                :participant="participant"
                :local="participant.id === context.localParticipant.id"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-if="$vuetify.breakpoint.mobile"
          class="hidden-lg-and-up"
        >
          <v-col
            v-for="participant in sidebarParticipants"
            :key="participant.id"
            sm="3"
            class="p-3"
          >
            <participant-viewer
              style="max-width: 100%"
              :participant="participant"
              :local="participant.id === context.localParticipant.id"
            />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-btn
            :class="context.localWebcamOn ? 'success mx-1' : 'grey mx-1'"
            icon
            @click="onToggleWebcam"
          >
            <v-icon>mdi-video-outline</v-icon>
          </v-btn>

          <v-btn
            :class="context.localMicOn ? 'success mx-1' : 'grey mx-1'"
            icon
            @click="onToggleMic"
          >
            <v-icon>mdi-microphone</v-icon>
          </v-btn>

          <v-btn
            :class="context.localScreenShareOn ? 'success mx-1' : 'grey mx-1'"
            icon
            @click="onToggleScreenShare"
          >
            <v-icon>mdi-monitor-screenshot</v-icon>
          </v-btn>
          <v-btn
            v-if="context.muted"
            dark
            class="green white--text ml-3"
            @click="context.unmute()"
          >
            <v-icon>
              mdi-volume-high
            </v-icon>
            <span class="pl-3">
              {{ $t('general.activer_le_son') }}
            </span>
          </v-btn>
        </v-row>
      </v-container>
    </v-row>
  </div>
</template>

<script>
  import ParticipantViewer from './ParticipantViewer.vue'

  export default {
    components: {
      ParticipantViewer,
    },

    props: {
      context: Object,
    },

    data: () => ({
      intervalFocusContainerHeight: null,
    }),

    computed: {
      screenShareParticipant() {
        const shareParticipants = Object.values(this.context.shareParticipants)
        return shareParticipants.length > 0 ? shareParticipants[0] : null
      },

      focusedParticipant() {
        const localParticipantId = this.context.localParticipant.id
        let videoParticipants = Object.values(this.context.videoParticipants)
        // Do not show my webcam to me if someone else is in the meeting
        if (videoParticipants.length > 1) {
          videoParticipants = videoParticipants.filter(p => p.id !== localParticipantId)
        }

        const allParticipants = Object.values(this.context.participants)
        const speakerId = this.context.nonLocalSpeaker
        if (speakerId != null) {
          const speaker = Object.values(this.context.participants).find(p => speakerId === p.id)
          if (speaker) return speaker
        }

        return videoParticipants.length > 0 ? videoParticipants[0] : allParticipants[0]
      },

      sidebarParticipants() {
        const focus = this.focusedParticipant
        const screenShare = this.screenShareParticipant
        let pinnedParticipants = Object.values(this.context.participants)
        if (focus && !screenShare) {
          pinnedParticipants = pinnedParticipants.filter(p => p.id !== focus.id)
        }
        return pinnedParticipants
      },
    },

    mounted() {
      this.intervalFocusContainerHeight = setInterval(this.setFocusContainerHeight, 100)
      window.focusContainer = this.$refs.focusContainer

      if (this.exposant) {
        console.log('Kiosque Videosdk - Pinned')
        this.context?.localParticipant.pin()
      }
    },

    destroyed() {
      clearInterval(this.intervalFocusContainerHeight)
    },

    methods: {
      onToggleWebcam() {
        this.context?.toggleWebcam()
      },

      onToggleMic() {
        this.context?.toggleMic()
      },

      onToggleScreenShare() {
        this.context?.toggleScreenShare()
      },

      setFocusContainerHeight() {
        // console.log(this.$refs.focusContainer.width, this.$refs.focusContainer.height)
        const container = this.$refs.focusContainer
        const width = container.offsetWidth
        container.style.height = `${width / 1.67}px`
      },
    },
  }
</script>

<style scoped>
.salle-privee-meeting-container {
  padding: 16px;
  width: 100%;
  background-color: #5f6368;
}
</style>
